:root {
  --pink: #ff0084;
  --white: #fff;
}

.footer {
  display: flex;
  height: 75px;
  padding: 0 50px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--nav2_background);
  color: var(--body_color);
  font-weight: 900;
  position: relative;
}

.footer span:nth-child(2) {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.list {
  display: flex;
  list-style-type: none;
}

.item {
  border-radius: 5px;
  padding: 10px;
}

.logo {
  fill: var(--body_color);
  width: 25px;
  margin-right: 5px;
}

.logo:hover {
  fill: var(--secondary_color);
}

@media (max-width: 750px) {
  .footer {
    flex-direction: column;
  }

  .footer span:nth-child(2) {
    position: static;
    transform: none;
    text-align: center;
    width: 100%;
  }

  .footer span {
    margin-bottom: 10px;
  }

  .footer ul {
    padding: 0;
    margin: 0;
  }
}