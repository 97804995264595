@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.navbar {
  margin: 0;
  padding: 0;
  position: fixed;
  width: 100%;
  background-color: var(--nav_background);
  backdrop-filter: blur(20px);
  z-index: 10;
}

.nav-controls {
  display: flex;
  align-items: center;
}

.title {
  font-family: "Pacifico", cursive;
  color: var(--body_color);
  font-size: 35px;
  font-weight: 900;
  text-decoration: none;
  margin-left: 20px;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  padding: 0 24px;
}

.nav-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-links li {
  margin-right: 40px;
}

.nav-link {
  color: var(--body_color);
  position: relative;
  display: inline-block;
  padding: 0 5px;
  text-decoration: none;
  background: none;
  border: none;
  font-size: 20px;
}

.title:hover,
.nav-link:hover {
  color: var(--secondary_color);
  cursor: pointer;
}

.nav-link::after {
  color: var(--secondary_color);
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 5px;
  bottom: -5px;
  border-radius: 5px;
  left: 0;
  background-color: currentColor;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.nav-link:hover::after {
  transform: scaleX(1.03);
  transform-origin: bottom left;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}


.bar {
  height: 3px;
  width: 25px;
  background-color: var(--body_color);
  margin: 4px 0;
  transition: all 0.3s;
}

.hamburger.open .bar:nth-child(1) {
  transform: rotate(45deg) translate(10px, 10px);
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -6px);
}

@media (max-width: 1000px) {
  .navbar {
    background-color: var(--nav2_background);
  }

  .nav-links {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    background-color: var(--nav2_background);
    flex-direction: column;
    align-items: center;
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease;
  }

  .nav-links.open {
    max-height: 400px;
    padding-bottom: 20px;
  }

  .nav-link {
    padding: 15px 0;
    width: 100%;
    text-align: center;
  }

  .hamburger {
    display: flex;
  }
}