.project {
	border-radius: 5px;
	padding: 15px;
	text-align: center;
	box-shadow: 0px 1px 2px var(--secondary_color);
	background-color: #140019;
}

.project:hover {
	transform: scale(1.02);
	transition: transform 0.5s ease;
	overflow: hidden;
	box-shadow: 0px 1px 5px var(--secondary_color);
}

.project-name {
	font-size: 20px;
	margin: 10px;
}

.project-description {
	text-align: justify;
	font-size: 14px;
	color: var(--p_color);
	margin-bottom: 20px;
}

.project-image {
	width: 100%;
	height: 175px;
	object-fit: cover;
	object-position: 50% 1%;
	transition: transform 0.3s ease;
}

.project-image:hover {
	cursor: pointer;
}

.project .project-link {
	padding: 8px 20px;
	align-items: center;
	margin: 10px;
	font-size: 16px;
	background-color: var(--secondary_color);
	color: white;
	border: none;
	cursor: pointer;
	border-radius: 15px;
}

.project-link:hover {
	background-color: var(--secondary_dark_color);
}

.project-link img {
	width: 24px;
}

.tech-stack {
	display: flex;
	justify-content: center;
	gap: 8px;
	margin: 8px 0px;
}

.tech-icon {
	width: 30px;
	height: 30px;
	object-fit: scale-down;
	padding: 5px;
} 