.projects {
  margin: 0;
  min-height: 100vh;
  overflow: hidden;
}

.projects-info {
  text-align: center;
  padding: 125px;
}

.projects-title {
  font-size: clamp(30px, 6vw, 40px);
  margin-bottom: 20px;
}

.projects-text {
  text-align: center;
  font-size: clamp(16px, 6vw, 20px);
  margin-bottom: 30px;
}

.more-text {
  text-align: center;
  font-size: clamp(16px, 6vw, 20px);
  margin-top: 50px;
}

.projects-link {
  color: var(--secondary_color);
}

.projects-link:hover {
  color: var(--secondary_dark_color);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  width: 90vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 10px;
  background-color: inherit;
  color: var(--p_color);
  border: none;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 30px;
}

.close-button:hover {
  color: var(--secondary_color);
}

@media (max-width: 1000px) {
  .projects-info {
    padding: 100px 50px 50px 50px;
  }
}

@media (max-width: 500px) {
  .projects-info {
    padding: 100px 30px 30px 30px;
  }
}