.contact {
  margin: 0;
  min-height: 65vh;
  color: var(--body_color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-title {
  font-size: clamp(30px, 6vw, 40px);
  padding-bottom: 10px;
}

.contact-info {
  padding: 10px;
  text-align: center;
}

.contact-list {
  display: flex;
  list-style: none;
  padding: 10px;
  margin-top: 30px;
  max-width: 500px;
  box-shadow: 0px 0px 3px var(--secondary_color);
  border-radius: 30px;
}

.contact-list-item {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
}

.contact-img {
  max-width: 25px;
  height: auto;
  background-color: var(--secondary_color);
  padding: 10px;
  border-radius: 10px;
  margin-right: 5px;
}

.contact-src {
  padding: 5px;
  font-size: 16px;
  font-weight: 500;
  color: var(--body_color);
  background-color: inherit;
  border: none;
  position: relative;
  display: inline-block;
}

.contact-src:hover {
  color: var(--secondary_color);
  cursor: pointer;
}

.contact-src::after {
  color: var(--secondary_color);
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 5px;
  bottom: -5px;
  border-radius: 5px;
  left: 0;
  background-color: currentColor;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.contact-src:hover::after {
  transform: scaleX(1.03);
  transform-origin: bottom left;
}

@media (max-width: 500px) {
  .contact-list {
    flex-direction: column;
  }
}