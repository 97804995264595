@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

.home {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  z-index: -1;
}

.home-text {
  z-index: 1;
  max-width: 100vw;
  padding: 50px;
}

.home-text h1 {
  font-size: clamp(30px, 7.5vw, 60px);
  color: var(--body_color)
}

p {
  margin: 0;
  font-size: clamp(24px, 10vw, 30px);
  color: var(--p_color);
  text-align: justify;
}

.blob-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  filter: blur(50px);
}

.blob-container::before {
  content: "";
  display: block;
  width: clamp(300px, 95vw, 600px);
  height: 400px;
  background: linear-gradient(180deg, var(--secondary_color), var(--other_color));
  border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
  animation: sliderShape 5s linear infinite;
}

@keyframes sliderShape {
  0%, 100% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  40% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }
  50% {
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  65% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}

.Typewriter__wrapper {
  font-size: clamp(25px, 6vw, 38px);
  color: var(--type_color);
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.Typewriter__cursor {
  font-size: clamp(25px, 6vw, 38px);
  color: var(--type_color);
}

@media (max-width: 680px) {
  .home-text {
    width: auto;
    padding: 50px;
  }
}

@media (max-width: 450px) {
  .home-text p {
    text-align: left;
    font-size: 24px;
  }
}