.heart-preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
}

.icon {
  fill: transparent;
  stroke: var(--secondary_color);
  stroke-width: 20;
  cursor: pointer;
  position: relative;
  filter: drop-shadow(0 0 10px var(--secondary_color));
}

svg {
  overflow: visible;
  width: 10rem;
}

.heart path {
  stroke-dashoffset: 0;
  stroke-dasharray: 1550;
  transform-origin: center;
  animation: neon-glow 1s infinite alternate, stroke-animation 1s ease-in-out forwards;
}

@keyframes neon-glow {
  from {
    filter: drop-shadow(0 0 10px var(--secondary_color));
  }
  to {
    filter: drop-shadow(0 0 30px var(--secondary_color));
  }
}

@keyframes stroke-animation {
  0% {
    stroke-dashoffset: 1550;
    opacity: 0.2;
  }
  100% {
    stroke-dashoffset: 3100;
    opacity: 1;
  }
}
