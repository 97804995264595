.about {
  margin: 0;
  min-height: 100vh;
  color: var(--body_color);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
  "info info img"
  "skills skills skills";
}

.about-info {
  grid-area: info;
  padding: 150px 100px 0 150px;
}

.about-title {
  font-size: clamp(30px, 6vw, 40px);
  padding-bottom: 20px;
}

.about-text {
  font-size: clamp(16px, 6vw, 20px);
  color: var(--p_color);
  text-align: left;
}

.highlight {
  color: var(--type_color);
}

.about-img-container {
  grid-area: img;
  padding: 150px 0 0 0;
}

.about-img {
  height: 300px;
  width: 300px;
  object-fit: cover;
  filter: brightness(0.9);
  border-radius: 30px;
}

.about-skills {
  grid-area: skills;
  padding: 20px 150px 20px 150px;
}

.skills {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  list-style: none;
  padding: 0;
  margin-top: 30px;
  text-align: center;
}

.skills img {
  max-width: 50px;
  height: auto;
  transition: transform 0.3s ease;
}

.skills li {
  position: relative;
  margin-bottom: 40px;
}

.skills li:hover img {
  transform: scale(1.5);
}

.skills li img:hover {
  transform: scale(1.5);
}

.skills li::after {
  content: attr(data-skill);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 20px;
  font-size: 14px;
  color: var(--body_color);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.skills li:hover::after {
  opacity: 1;
}

@media (max-width: 1000px) {
  .about {
    grid-template-columns: 1fr;
    grid-template-areas:
    "img"
    "info"
    "skills"
  }

  .about-info {
    padding: 30px 50px 20px 50px;
  }

  .about-title, .about-text, .about-img-container {
    text-align: center;
  }

  .about-skills {
    padding: 50px;
  }
}

@media (max-width: 500px) {
  .about-info {
    padding: 30px;
  }

  .about-skills {
    padding: 30px;
  }

}