@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --body_background: #0f0013;
  --body_color: white;
  --secondary_color: #ff0084;
  --secondary_dark_color: #c30065;
  --other_color: #312ee5;
  --p_color: #eee;
  --type_color: #ebabcb;
  --nav_background: #0000009f;
  --nav2_background: black;
}

* {
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--body_background);
  font-family: "Roboto", sans-serif;
  color: var(--body_color);
}

.page {
  max-width: 1490px;
  margin: 0 auto;
}